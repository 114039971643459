.map {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}

.map .ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.map .ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}
