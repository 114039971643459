.datePicker {
  padding: 0 20px 10px 20px;
}
.datePicker table {
  border-collapse: collapse;
}
.datePicker th,
.datePicker td {
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 0;
}
